import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { StoreService } from '@core/services/store.service';

export const authGuard: CanActivateFn = async (route, state) => {
  const router = inject(Router);
  if (StoreService.User) {
    router.navigate(['chat']);
    return false;
  }
  return true;
};

export const chatGuard: CanActivateFn = async (route, state) => {
  const router = inject(Router);
  if (!StoreService.User) {
    router.navigate(['auth']);
    return false;
  }
  return true;
};
