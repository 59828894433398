<main>
  <div class="bg">
    <div class="r"></div>
    <div class="r"></div>
    <div class="r"></div>
  </div>
  <div class="bg-blur"> </div>
  <router-outlet></router-outlet>
</main>
<div class="version r4">{{version}}</div>
<loading></loading>
