import { IEnvironment } from "./environment.interface";

export const environment: IEnvironment = {
  poduction: false,
  firebaseConfig: {
    apiKey: "AIzaSyCP_s0yUShQMn5cQ6J3XrdIGUTDd-ur_d4",
    authDomain: "translationtchat-development.firebaseapp.com",
    projectId: "translationtchat-development",
    storageBucket: "translationtchat-development.appspot.com",
    messagingSenderId: "637433293531",
    appId: "1:637433293531:web:1f260b069d9abd5f64bafe",
    measurementId: "G-3MX0ZJ5ZBG"
  } as const
};
