import { ChangeDetectorRef, Component, ElementRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Subscription } from 'rxjs';
import { LoadingService } from '@core/services/loading.service';

@Component({
  selector: 'loading',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent {
  loadingSubscription!: Subscription;

  constructor(private _elmRef: ElementRef, private _changeDetectorRef: ChangeDetectorRef) { }

  ngAfterViewInit(): void {
    this._elmRef.nativeElement.style.display = 'none';
    this.loadingSubscription = LoadingService.$$().pipe()
      .subscribe((status: boolean) => {
        this._elmRef.nativeElement.style.display = status ? 'flex' : 'none';
        this._changeDetectorRef.detectChanges();
      });
  }

  ngOnDestroy() {
    this.loadingSubscription.unsubscribe();
  }
}
