import { IMessage, IUser } from "./models";
import * as CryptoJS from 'crypto-js';

export enum STORAGE_KEY {
  MODE = 'mode',
  THEME = 'theme'
}

export enum CRYPTO {
  TOKEN_KEY = "NZqgQsa2Ypw7JhMd",
  TOKEN_IV = "MfUIIkQgnbuZPM8S",
}

export enum COLLECTION {
  CONST = 'const',
  CONVERSATION = 'conversations',
  INFO = 'info',
  LANGUAGE = 'langages',
  LICENCES = 'licences',
  RESOURCES = 'resources',
  USERS = 'users'
}

export interface IStyleConfig {
  id: string;
  primaryColor: string;
  neutralColor1: string;
  neutralColor2: string;
  neutralColor3: string;
  neutralColor4: string;
  alertLink: string;
  alertWarning: string;
  alertError: string;
  alertSuccess: string;
  whiteColor: string;
  backgroundColor: string;
  blackColor: string;
  messageColor: string;
  textWhiteColor: string;
}

export const StyleConfigDefault: IStyleConfig = {
  id: "d1",
  primaryColor: "#48BFC5",
  neutralColor1: "#3b3b3b",
  neutralColor2: "#666666",
  neutralColor3: "#9b9b9b",
  neutralColor4: "#b9b9b9",
  alertLink: "#006fff",
  alertWarning: "#ffcc00",
  alertError: "#ff0000",
  alertSuccess: "#339900",
  whiteColor: "#ffffff",
  backgroundColor: "#404040",
  blackColor: "#222222",
  messageColor: "#2b2b2b",
  textWhiteColor: "#ffffff"
} as const;

export const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(.*).{6,}$/;

export enum MESSAGE_TYPE {
  TEXT = 'TEXT',
  DELETED = 'DELETED',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  PDF = 'PDF',
  OTHER = 'OTHER'
}

export enum FILE_STATUS {
  UPLOADED = 'UPLOADED',
  UPLOADING = 'UPLOADING',
  ERROR = 'ERROR'
}

export enum CONVERSATION_TYPE {
  PRIVATE = 'PRIVATE',
  GROUP = 'GROUP'
}

export enum TRANSLATE_TOOL {
  CHAT_GPT = 'chatgpt',
  AZURE = 'azure',
  GOOGLE = 'google'
}

export enum TRANSLATE_STATUS {
  TRANSLATING = 'TRANSLATING',
  TRANSLATED = 'TRANSLATED',
  ERROR = 'ERROR'
}

export enum EMOJI {
  LIKE = 'like',
  HEART = 'heart',
  PERSON_HEART = 'person_heart',
  LAUGH = 'haha',
  WOW = 'wow',
  SAD = 'sad',
  ANGRY = 'angry'
}

export enum USER_TYPE {
  FREE = 'free',
  INTERNAL = 'internal'
}

export interface IContact {
  companyName: string;
  users: IUser[];
}

export interface IMessageCustom {
  senderId: string;
  avatar: string;
  locale: string;
  messages: IMessage[];
}

export const aesKey = CryptoJS.enc.Base64.parse("LVL9Sko96B/5wMKG3xqR+AJqPuJ28+u/Y/uK03509/E=");
