import { HttpClient } from '@angular/common/http';
import { COLLECTION, IStyleConfig, STORAGE_KEY, StyleConfigDefault } from './const';
import { LocalStorageCustomService } from './services/local-storage-custom.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { catchError, forkJoin, from, mergeMap, of, tap } from 'rxjs';
import { StoreService } from './services/store.service';
import { IConst, IUser } from './models';
import { AngularFireAuth } from '@angular/fire/compat/auth';

export function ApplicationInitializeConfig(http: HttpClient, fbs: AngularFirestore, auth: AngularFireAuth) {
  return async () => {
    try {
      await new Promise((resole, reject) => forkJoin([getStyleConfig(http), getLanguage(fbs, auth)])
        .subscribe(() => {
          console.log('ApplicationInitializeConfig done!!!')
          resole(true);
        }));
    }
    catch (err) { console.log(err); }
  }
}

export function getStyleConfig(http: HttpClient) {
  let mode = LocalStorageCustomService.Get(STORAGE_KEY.MODE);
  let theme = LocalStorageCustomService.Get(STORAGE_KEY.THEME);
  mode = mode || mode !== '' ? mode : 'light';
  theme = theme || theme !== '' ? theme : '1';
  const url = `/assets/style/${mode}/theme${theme}.json`;
  return http.get(url).pipe(tap(res => ApplyStyleConfig(res as IStyleConfig, mode, theme)),
    catchError(err => { ApplyStyleConfig(); throw err; }))
}

export function getLanguage(fbs: AngularFirestore, auth: AngularFireAuth) {
  let uid: string | null | undefined;
  return fbs.collection(COLLECTION.CONST).get().pipe(
    mergeMap(res => {
      if (res) {
        StoreService.Const = res.docs.map(d => d.data())[0] as IConst;
        StoreService.LanguegeDocs = Object.keys(StoreService.Const.languages);
      }
      return from(auth.currentUser)
    }),
    mergeMap(res => {
      if (res) uid = res.uid;
      return uid ? fbs.collection(COLLECTION.USERS).doc<IUser>(uid).get() : of(null);
    }),
    mergeMap(res => {
      let locale: string;
      let user = res?.data();
      if (user) {
        locale = user.locale;
        StoreService.User = user;
      }
      else {
        const userLanguage = window.navigator.language.split('-')[0] ?? 'en';
        locale = StoreService.LanguegeDocs.find(k => k.indexOf(userLanguage) >= 0)!;
      }
      return fbs.collection(COLLECTION.LANGUAGE).doc(locale).get();
    }),
    tap(res => {
      if (res) StoreService.LanguageJson = res.data() as JSON;
      console.log(res?.data());
    })
  )
}

export function ApplyStyleConfig(config: IStyleConfig = StyleConfigDefault, mode: string = 'light', theme: string = '1') {
  StoreService.StyleConfig = config;
  StoreService.Mode = mode;
  StoreService.Theme = `theme${theme}`;
  const id = 'tchat-style';
  let style = document.getElementById(id);
  style && document.head.removeChild(style);
  style = document.createElement('style');
  style.id = id;
  style.innerHTML = `
    :root {
      --primary-color: ${config.primaryColor};
      --neutral-color-1: ${config.neutralColor1};
      --neutral-color-2: ${config.neutralColor2};
      --neutral-color-3: ${config.neutralColor3};
      --neutral-color-4: ${config.neutralColor4};
      --alert-link: ${config.alertLink};
      --alert-warning: ${config.alertWarning};
      --alert-error: ${config.alertError};
      --alert-success: ${config.alertSuccess};
      --white-color: ${config.whiteColor};
      --background-color: ${config.backgroundColor};
      --black-color: ${config.blackColor};
      --message-color: ${config.messageColor};
      --text-white-color: ${config.textWhiteColor};
    }
  `;
  document.head.appendChild(style);
}
