import { IConst, IConversation, IUser } from "@core/models";
import { IStyleConfig, StyleConfigDefault } from "../const";

export class StoreService {
  public static LanguageJson: JSON;
  public static LanguegeDocs: string[];
  public static StyleConfig: IStyleConfig = StyleConfigDefault;
  public static Mode: string;
  public static Theme: string;
  public static Const: IConst;
  public static User: IUser;
  public static Users: IUser[];
  public static Conversations: IConversation[] = [];
}
