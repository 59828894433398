import { TranslateLoader } from "@ngx-translate/core";
import { of } from "rxjs";
import { StoreService } from "./services/store.service";

export class CustomTranslateLoader implements TranslateLoader {
  constructor() { }

  getTranslation(lang: string) {
    return of(StoreService.LanguageJson);
  }
}
